import React, {
    useCallback,
    useState,
    useMemo,
    useEffect,
    useRef,
} from 'react'
import {
    Row,
} from '@silevis/reactgrid'
import isEqual from 'lodash/isEqual'
import useStoreSpreadSheetConfig from 'app/store/SpreadSheetConfigs/useStoreSpreadSheetConfig'

import ReactGridExtended from './ReactGridExtended'
import {
    SkyNetSpreadSheetConfigType,
} from './SkyNetSpreadSheet.types'
import useStyles from './SkyNetSpreadSheet.styles'
import getRows, {
    rowToDataConverter,
} from './getRows'
import useGetColumns from './hooks/useGetColumns'
import {
    SkyNetSpreadSheetContext,
} from './SkyNetSpreadSheetContext'
import RecalculationWrapper from './RecalculationWrapper'
import useSpreadsheetMapping from './hooks/useSpreadsheetMapping'
import useApplyResolvedValues from './hooks/useApplyResolvedValues'
import useExcludeEmptyRows, {
    isRowNotEmpty,
} from './hooks/useExcludeEmptyRows'
import useSpreadsheetContextMenu from './hooks/useSpreadsheetContextMenu'
import useAddRows from './hooks/useAddRows'
import useCreateEmptyRow from './hooks/useCreateEmptyRow'
import useGetTemplates from './hooks/useGetTemplates'
import useDeleteRow from './hooks/useDeleteRow'
import useLoadFilteredData from './hooks/useLoadFilteredData'
import useCellChanges from './hooks/useCellChanges'
import useInitValue from './hooks/useInitValue'
import useSkyNetSpreadSheetValidationContext from './SkyNetSpreadsheetValidation'

const defaultProps = {
    validateRow: isRowNotEmpty,
    classes: undefined,
    value: [],
}

function SkyNetSpreadSheet<F = any>({
    config,
    value,
    disabled,
    onChange,
    validateRow,
    classes,
}: Readonly<{
    config: SkyNetSpreadSheetConfigType<F>,
    value?: Record<string, any>[],
    disabled?: boolean,
    onChange: (...args: any[]) => void,
    validateRow?: (item: Record<string, any>) => boolean,
    classes?: string,
}>) {
    const {
        styles, getClasses,
    } = useStyles()

    const [
        locked,
        setLocked,
    ] = useState<boolean>(disabled)

    const containerRef = useRef(null)
    const gridRef = useRef(null)

    const [
        focusChanging,
        setFocusChanging,
    ] = useState(true) // when focus changing is false, navigation between cells is disabled

    const customCellTemplates = useGetTemplates(config)
    const createEmptyDataRow = useCreateEmptyRow(config)
    const {
        columns, recalculationIndex,
    } = useGetColumns(config, containerRef)

    const [
        data,
        setData,
    ] = useState([
        ...value,
        ...Array.from(Array(config.emptyRows || 0), () => {
            return createEmptyDataRow()
        }),
    ])

    const {
        showError,
        setValid,
        valid,
    } = useSkyNetSpreadSheetValidationContext()
    const excludeEmptyRows = useExcludeEmptyRows(validateRow)

    useEffect(() => {
        const filteredData = excludeEmptyRows(data)

        if (!isEqual(filteredData, value)) {
            onChange(filteredData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    // creates rows array applicable for ReactGrid
    const rows = useMemo(() => {
        return getRows({
            data,
            config,
            styles,
            spreadSheetDisabled: disabled || locked,
            showError,
            setValid,
            valid,
            validateRow,
        })
    }, [
        data,
        config,
        styles,
        disabled,
        locked,
        showError,
        setValid,
        valid,
        validateRow,
    ])

    const addDataRows = useAddRows({
        rowsLimit: config.rowsLimit,
        dataLength: data.length,
        setData,
        createEmptyDataRow,
    })

    const deleteDataRow = useDeleteRow(setData)
    const convertRowsToData = useCallback((rowsState: Row[]) => {
        return rowsState.reduce(rowToDataConverter(columns), [])
    }, [columns])

    const onRequest = useCallback((positive: boolean) => {
        setLocked(!positive)
        setFocusChanging(positive)
    }, [
        setLocked,
        setFocusChanging,
    ])

    const handleContextMenu = useSpreadsheetContextMenu({
        config,
        addDataRows,
        deleteDataRow,
        disabled,
    })
    const getSpreadsheetMappings = useSpreadsheetMapping({
        onSuccess: onRequest,
        configId: config.id,
    })
    const applyResultsToValues = useApplyResolvedValues(config.id)

    const onCellsChanged = useCellChanges({
        applyResultsToValues,
        config,
        convertRowsToData,
        data,
        disabled,
        getSpreadsheetMappings,
        locked,
        setData,
        styles,
        validateRow,
    })

    const forceEditQuit = useCallback(() => {
        return gridRef.current?.state.update((state) => {
            return {
                ...state,
                currentlyEditedCell: null,
            }
        })
    }, [])

    // created context for handling spreadsheet navigation
    const skyNetSpreadSheetContext = useMemo(() => {
        return {
            focusChanging,
            setFocusChanging,
            rowHeight: config.rowHeight,
            forceEditQuit,
            configId: config.id,
        }
    }, [
        forceEditQuit,
        focusChanging,
        setFocusChanging,
        config.rowHeight,
        config.id,
    ])

    const onFocusLocationChanging = useCallback(() => {
        return focusChanging
    }, [focusChanging])

    const spreadSheetConfig = useMemo(() => {
        return {
            name: config.id,
            fields: config.fields,
        }
    }, [
        config.fields,
        config.id,
    ])

    const [
        initValue,
        discardInitValue,
    ] = useInitValue(value)

    useLoadFilteredData({
        emptyRows: config.emptyRows,
        createEmptyDataRow,
        setData,
        discardInitValue,
    })
    useStoreSpreadSheetConfig(spreadSheetConfig, initValue)

    return (
        <RecalculationWrapper
            recalculationIndex={recalculationIndex}
            getClasses={getClasses}
            classNames={classes}
            maxHeight={config.maxHeight}
            ref={containerRef}
        >
            <SkyNetSpreadSheetContext.Provider value={skyNetSpreadSheetContext}>
                <ReactGridExtended
                    ref={gridRef}
                    columns={columns}
                    rows={rows}
                    enableFillHandle
                    enableFullWidthHeader
                    disableVirtualScrolling
                    enableRowSelection={config.enableRowSelection}
                    enableColumnSelection={config.enableColumnSelection}
                    enableRangeSelection={config.enableRangeSelection}
                    stickyTopRows={config.stickyTopRows}
                    stickyBottomRows={config.stickyBottomRows}
                    stickyLeftColumns={config.stickyLeftColumns}
                    stickyRightColumns={config.stickyRightColumns}
                    onCellsChanged={onCellsChanged}
                    onContextMenu={handleContextMenu}
                    customCellTemplates={customCellTemplates}
                    onFocusLocationChanging={onFocusLocationChanging}
                />
            </SkyNetSpreadSheetContext.Provider>
        </RecalculationWrapper>
    )
}

SkyNetSpreadSheet.defaultProps = defaultProps

export default SkyNetSpreadSheet

export enum ServiceCenterStatus {
    PLANNED = 'PLANNED',
    INACTIVE = 'INACTIVE',
    ACTIVE = 'ACTIVE',
}

export enum ServiceCenterType {
    DRY_STORAGE_CENTER = 'DRY_STORAGE_CENTER',
    CONSIGNMENT_CENTER = 'CONSIGNMENT_CENTER',
    REPAIR_CENTER = 'REPAIR_CENTER',
    SERVICE_CENTER = 'SERVICE_CENTER',
    OVERHAUL_CENTER = 'OVERHAUL_CENTER',
    PRODUCTION_SITE = 'PRODUCTION_SITE',
}

export enum OperationalDays {
    MONDAY_FRIDAY = 'MONDAY_FRIDAY',
    MONDAY_SUNDAY = 'MONDAY_SUNDAY',
    SUNDAY_THURSDAY = 'SUNDAY_THURSDAY',
    MONDAY_SATURDAY = 'MONDAY_SATURDAY',
}

export type ServiceCentersTableType = {
    serviceCenterNumber: number,
    location: {id: number},
    serviceCenterStatus: ServiceCenterStatus,
    serviceCenterType: ServiceCenterType,
    operationalDays: OperationalDays,
    orderSelectionDelay: number,
}

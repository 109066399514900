/* eslint-disable class-methods-use-this */
import React from 'react'
import {
    Cell,
    CellTemplate,
    Compatible,
    getCellProperty,
    keyCodes,
    Uncertain,
    UncertainCompatible,
} from '@silevis/reactgrid'
import store from 'app/store'
import {
    selectSpreadSheetConfigByDomain,
} from 'app/store/SpreadSheetConfigs'
import {
    DelayedLoadOptions,
} from 'app/hooks/useDelayedLoadOptions'
import Typeahead from './TypeaheadCell'

import {
    Domain,
    SkyNetSpreadSheetCellType, SpreadsheetCellOption,
} from '../../SkyNetSpreadSheet.types'
import SelectableElement from '../../SelectableElement'

export interface TypeaheadCell extends Cell {
    type: SkyNetSpreadSheetCellType.TYPEAHEAD,
    domain: Domain,
    loadOptions: (o: DelayedLoadOptions) => Promise<SpreadsheetCellOption[]>,
    filters?: Record<string, any>,
    name: string,
    text?: string,
    id?: number,
    selectedValue: Record<string, any>,
    undeterminedValue: string | null,
    value?: number,
    onChangeAutopopulate?: (...args: any) => any,
    onChangeOverride?: (...args: any) => any,
}

export default class TypeaheadCellTemplate implements CellTemplate<TypeaheadCell> {
    private configId: string

    constructor({
        configId,
    }: {
        configId: string
    }) {
        this.configId = configId
    }

    getCellOptionsFromStore(cell: UncertainCompatible<TypeaheadCell>) {
        const {
            options,
        } = selectSpreadSheetConfigByDomain(
            store.getState(), {
                name: this.configId,
                field: cell.name,
                domain: cell.domain,
            },
        )

        return options
    }

    getCompatibleCell(uncertainCell: Uncertain<TypeaheadCell>): Compatible<TypeaheadCell> {
        let selectedValue
        let text
        let undeterminedValue

        try {
            selectedValue = getCellProperty(uncertainCell, 'selectedValue', 'object')
        } catch {
            selectedValue = null
        }

        try {
            text = getCellProperty(uncertainCell, 'text', 'string')
        } catch {
            text = null
        }

        try {
            undeterminedValue = getCellProperty(uncertainCell, 'undeterminedValue', 'string')
        } catch {
            undeterminedValue = null
        }

        return {
            ...uncertainCell,
            undeterminedValue,
            selectedValue,
            text,
            name: uncertainCell.name,
            domain: uncertainCell.domain,
            loadOptions: uncertainCell.loadOptions,
            value: selectedValue?.value,
        }
    }

    handleKeyDown(
        cell: Compatible<TypeaheadCell>,
        keyCode: number,
    ): { cell: Compatible<TypeaheadCell>, enableEditMode: boolean } {
        if (keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER
            || keyCode === keyCodes.SPACE) {
            return {
                cell,
                enableEditMode: true,
            }
        }
        return {
            cell, enableEditMode: false,
        }
    }

    findByText(cell: UncertainCompatible<TypeaheadCell>): SpreadsheetCellOption {
        return (this.getCellOptionsFromStore(cell) || []).find(({
            label, textLabel,
        }) => {
            return textLabel === cell.text || label === cell.text
        }) || {}
    }

    findByValue(cell: UncertainCompatible<TypeaheadCell>): SpreadsheetCellOption {
        return (this.getCellOptionsFromStore(cell) || []).find(({
            value, id,
        }) => {
            return id === cell.value || value === cell.value
        }) || {}
    }

    onCopyPaste(
        cell: Compatible<TypeaheadCell>,
        cellToMerge: UncertainCompatible<TypeaheadCell>,
    ): Compatible<TypeaheadCell> {
        const {
            id, value, textLabel, label,
        } = this.findByText({
            ...cell, ...cellToMerge,
        }) || {}

        if (value) {
            return this.getCompatibleCell({
                ...cell,
                text: textLabel || label,
                selectedValue: {
                    id: id || value,
                    value,
                    textLabel: textLabel || label,
                    label,
                },
            })
        }

        return this.getCompatibleCell({
            ...cell,
            text: null,
            undeterminedValue: cellToMerge.text,
        })
    }

    onInternalChanges(
        cell: Compatible<TypeaheadCell>,
        cellToMerge: UncertainCompatible<TypeaheadCell>,
    ): Compatible<TypeaheadCell> {
        if (cellToMerge.value && cell.value !== cellToMerge.value) {
            const {
                textLabel, label,
            } = this.findByValue(cellToMerge)

            return this.getCompatibleCell({
                ...cell,
                ...cellToMerge,
                text: textLabel || label || cellToMerge.text,
                selectedValue: {
                    ...cellToMerge.selectedValue || {},
                    id: cellToMerge.value,
                    value: cellToMerge.value,
                    textLabel: textLabel || label || cellToMerge.text,
                    label: label || cellToMerge.text,
                },
            })
        }

        if (cell.text !== cellToMerge.text) {
            const {
                id, value, textLabel, label,
            } = this.findByText(cellToMerge) || {}

            return this.getCompatibleCell({
                ...cell,
                ...cellToMerge,
                selectedValue: {
                    ...cellToMerge.selectedValue || {},
                    id: id || cellToMerge.value,
                    value: value || cellToMerge.value,
                    textLabel: textLabel || label || cellToMerge.text,
                    label: label || cellToMerge.text,
                },
            })
        }

        return {
            ...cell,
            ...cellToMerge,
        }
    }

    update(
        cell: Compatible<TypeaheadCell>,
        cellToMerge: UncertainCompatible<TypeaheadCell>,
    ): Compatible<TypeaheadCell> {
        if (cellToMerge.type === SkyNetSpreadSheetCellType.TYPEAHEAD) {
            return this.onInternalChanges(cell, cellToMerge)
        }

        return this.onCopyPaste(cell, cellToMerge)
    }

    render(
        cell: Compatible<TypeaheadCell>,
        isInEditMode: boolean,
        onCellChanged: (cell: Compatible<TypeaheadCell>, commit: boolean) => void,
    ): React.ReactNode {
        const onChange = ({
            label, value, textLabel, ...rest
        }) => {
            return onCellChanged(this.getCompatibleCell({
                ...cell,
                selectedValue: {
                    ...rest,
                    id: value,
                    label: textLabel || label,
                    textLabel,
                    value,
                },
                text: textLabel || label,
            }), true)
        }

        if (isInEditMode) {
            return (
                <Typeahead
                    cell={cell}
                    onChange={onChange}
                />
            )
        }

        return (
            <SelectableElement
                disabled={cell?.nonEditable}
                text={cell?.selectedValue?.textLabel || cell?.selectedValue?.label}
                name={cell?.name}
                id={cell?.value}
                editMode={false}
            />
        )
    }
}

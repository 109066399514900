import React, {
    useCallback,
    useMemo, useState,
} from 'react'
import isEmpty from 'lodash/isEmpty'
import {
    AsyncMutationOptions,
} from 'app/types/request.types'
import {
    FilterContext, FilterContextType,
    FilteredData,
    FilterOptions,
} from 'app/hooks/useFilter'
import useLabel from 'app/hooks/useLabel'
import useEnumValues from 'app/hooks/useEnumValues'
import isEqual from 'lodash/isEqual'
import locationRequests from 'app/Apps/ContactManagement/Locations/Locations.request'
import {
    useLocationAirportLoadOptions,
} from 'app/shared-components/LocationSelector'
import useDelayedLoadOptions from 'app/hooks/useDelayedLoadOptions'
import DataContentWrapper from 'app/shared-components/DataContentWrapper'
import SkyNetSpreadSheet, {
    OnChangeCellFunction,
    SkyNetSpreadSheetControlPanel, useTypeaheadOptions,
} from 'app/shared-components/SkyNetSpreadSheet'
import {
    AddressMainCategory,
} from 'app/Apps/ContactManagement/Locations/Locations.types'
import {
    LaneStatus,
} from 'app/types/enums'
import moment from 'moment/moment'
import strToDate from 'app/utils/date/strToDate'
import useFetchLanes from './hooks/useFetchLanes'

import preBookingsFilterConfig
    from './PreBookingSpreadsheet.filterConfig'
import getConfig, {
    PreBookingEntitySpreadsheetData,
} from './PreBookingSpreadsheet.config'
import {
    LaneSelector,
} from '../../Pricing/Pricing.types'
import {
    convertToPrebookingEntity,
} from './PrebookingSpreadsheet.utils'

const PreBookingSpreadsheet = ({
    onSave, onCancel, enableAddNewRows, filterOptions,
}: Readonly<{
    onSave?: (v: any[], o?: AsyncMutationOptions) => void
    onCancel?: (...args: any[]) => void,
    enableAddNewRows?: boolean,
    filterOptions?: FilterOptions,
}>) => {
    const getLabel = useLabel()
    const getAllowedValues = useEnumValues()
    const getLanes = useFetchLanes()

    const [
        value,
        setValue,
    ] = useState(filterOptions?.filteredData as FilteredData<PreBookingEntitySpreadsheetData[]>)

    const filterByAccount = useMemo(() => {
        return {
            pricingAccountId: filterOptions?.filter?.accountId,
        }
    }, [filterOptions?.filter])

    const handleSave = useCallback(async (options?: { onSuccess?: (...a: any) => void }) => {
        return onSave(convertToPrebookingEntity(value), options)
    }, [
        onSave,
        value,
    ])

    const edited = !isEqual(filterOptions?.filteredData, value)

    const saveBeforeFilter = useCallback((setFilter) => {
        handleSave({
            onSuccess: setFilter,
        })
    }, [handleSave])

    const filterConfig: FilterContextType = useMemo(() => {
        return {
            ...filterOptions,
            config: preBookingsFilterConfig,
            beforeFilterCallback: saveBeforeFilter,
            edited,
        }
    }, [
        edited,
        filterOptions,
        saveBeforeFilter,
    ])

    const loadLocationAirportOptions = useLocationAirportLoadOptions()
    const locationAirportParams = useTypeaheadOptions({
        load: loadLocationAirportOptions,
    })

    const serviceCenterOptions = useMemo(() => {
        return {
            url: locationRequests.all().url,
            includeFilters: {
                addressMainCategory: [AddressMainCategory.SERVICECENTER],
            },
            convertion: (locations) => {
                return locations.map((location) => {
                    return {
                        ...location,
                        value: location.id,
                        label: location.locationName,
                    }
                })
            },
        }
    }, [])

    const loadServiceCenterOptions = useDelayedLoadOptions(serviceCenterOptions)
    const serviceCenterParams = useTypeaheadOptions({
        load: loadServiceCenterOptions,
    })

    const applyLaneFilter = useCallback(({
        originAirport, destinationAirport,
    }: PreBookingEntitySpreadsheetData) => {
        return {
            originAirportId: [originAirport?.id],
            destinationAirportId: [destinationAirport?.id],
        }
    }, [])

    const onLaneChange: OnChangeCellFunction = useCallback(({
        handoverPointLocationName,
        collectionDropoffPointLocationName,
    }: LaneSelector) => {
        return {
            handoverPointLocationName,
            collectionDropoffPointLocationName,
        }
    }, [])

    const loadAvailableLanes = useCallback((options?: {
        phrase?: string,
        filters?: Record<string, any>,
    }) => {
        return getLanes({
            includeFilters: {
                laneStatus: [
                    LaneStatus.IMPLEMENTED,
                    LaneStatus.AWARDED,
                ],
                ...filterByAccount,
            },
            ...options,
        })
    }, [
        filterByAccount,
        getLanes,
    ])

    const laneParams = useTypeaheadOptions({
        load: loadAvailableLanes,
        isFilterRequired: true,
    })

    const noOriginDestinationSelected = useCallback(({
        destinationAirport, originAirport,
    }: PreBookingEntitySpreadsheetData) => {
        return isEmpty(destinationAirport) || isEmpty(originAirport)
    }, [])

    const onAirportChange: OnChangeCellFunction = useCallback(async (
        _, preBooking: PreBookingEntitySpreadsheetData,
    ) => {
        if (noOriginDestinationSelected(preBooking)) return undefined

        const lanes = await loadAvailableLanes({
            filters: {
                ...applyLaneFilter(preBooking),
            },
        })

        if (lanes?.length === 1) {
            return {
                lane: lanes[0],
                ...onLaneChange(lanes[0]) as Record<string, any>,
            }
        }

        return {
            lane: undefined,
            handoverPointLocationName: undefined,
            collectionDropoffPointLocationName: undefined,
        }
    }, [
        applyLaneFilter,
        loadAvailableLanes,
        noOriginDestinationSelected,
        onLaneChange,
    ])

    const onPrebookingHandoverAutopopulateChange: OnChangeCellFunction = useCallback(
        (requestedHandover) => {
            if (requestedHandover) {
                return {
                    handoverTimezone: moment().format('ZZ'),
                }
            }
            return undefined
        }, [],
    )

    const onPrebookingHandoverOverrideChange: OnChangeCellFunction = useCallback(
        (requestedHandover) => {
            if (requestedHandover) {
                const date = strToDate(requestedHandover)

                return {
                    requestedHandoverYear: date.getFullYear(),
                    requestedHandoverWeek: moment(date).week(),
                }
            }
            return undefined
        }, [],
    )

    const handoverYearWeekDisabled = useCallback(({
        requestedHandover,
    }: PreBookingEntitySpreadsheetData) => {
        return !isEmpty(requestedHandover)
    }, [])

    const onPrebookingPickupAutopopulateChange: OnChangeCellFunction = useCallback(
        (requestedPickup) => {
            if (requestedPickup) {
                return {
                    pickupTimezone: moment().format('ZZ'),
                }
            }
            return undefined
        }, [],
    )

    const config = useMemo(() => {
        return getConfig({
            getLabel,
            getAllowedValues,
            editMode: !enableAddNewRows,
            locationAirportParams,
            serviceCenterParams,
            laneParams,
            noOriginDestinationSelected,
            applyLaneFilter,
            onLaneChange,
            onAirportChange,
            onPrebookingHandoverAutopopulateChange,
            onPrebookingHandoverOverrideChange,
            handoverYearWeekDisabled,
            onPrebookingPickupAutopopulateChange,
        })
    }, [
        getLabel,
        getAllowedValues,
        enableAddNewRows,
        locationAirportParams,
        serviceCenterParams,
        laneParams,
        noOriginDestinationSelected,
        applyLaneFilter,
        onLaneChange,
        onAirportChange,
        onPrebookingHandoverAutopopulateChange,
        onPrebookingHandoverOverrideChange,
        handoverYearWeekDisabled,
        onPrebookingPickupAutopopulateChange,
    ])

    return (
        <FilterContext.Provider value={filterConfig}>
            <SkyNetSpreadSheetControlPanel
                onSave={handleSave}
                onCancel={onCancel}
                edited={edited}
            >
                <DataContentWrapper
                    isEmpty={!filterByAccount.pricingAccountId}
                    emptyDataReplacer={`Please select the account to ${enableAddNewRows ? 'add new' : 'edit'} pre-bookings`}
                >
                    <SkyNetSpreadSheet
                        config={config}
                        value={value}
                        disabled={false}
                        onChange={setValue}
                    />
                </DataContentWrapper>
            </SkyNetSpreadSheetControlPanel>
        </FilterContext.Provider>
    )
}

export default PreBookingSpreadsheet

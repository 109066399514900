import removeUnauthorizedField from 'app/utils/removeUnauthorizedField'
import {
    DTOCardFormField,
} from 'app/types/form.types'
import {
    LocationType,
} from 'app/types/enums'
import getLocationFieldDetails from './fields/getLocationFieldDetails'
import getAirportFieldDetails from './fields/getAirportFieldDetails'
import getAddressFieldDetails from './fields/getAddressFieldDetails'
import getShippingFieldDetails from './fields/getShippingFieldDetails'
import getSeaportFieldDetails from './fields/getSeaportFieldDetails'
import getServiceFieldDetails from './fields/getServiceFieldDetails'

import {
    LocationFormFields,
} from './LocationForm.types'

const getLocationFormFields = ({
    country,
    locationType,
    isShippingLocation,
    timeZones,
    isServiceCenter,
    categories,
    isNew,
    allowedCategories,
    setAutocomplete,
    timeZone,
    currentPlaceId,
    value,
    isGoogleMapsServiceLoaded,
    getAllowedValues,
    hasEditPermission,
    isProspect,
    orignalIsProspect,
    isAebServiceProvider,
    isCategoryForwarder,
    isProspectDisabled,
}): DTOCardFormField<LocationFormFields>[] => {
    const location = {
        id: 'location',
        title: 'Location',
        className: 'location',
        'data-testid': 'card-location',
        fields: [{
            id: 'column1',
            fields: removeUnauthorizedField(
                getLocationFieldDetails({
                    isNew,
                    hasEditPermission,
                    isProspect,
                    orignalIsProspect,
                    isAebServiceProvider,
                    isAddress: locationType === LocationType.ADDRESS,
                    isCategoryForwarder,
                    isProspectDisabled,
                }), ['locationNameRule'], locationType === LocationType.ADDRESS,
            ),
        }],
    }
    const airport = {
        id: 'airportDetails',
        title: 'Airport Details',
        className: 'airportDetails',
        'data-testid': 'card-aiportDetails',
        fields: [{
            id: 'column2',
            fields: getAirportFieldDetails({
                timeZones, country, getAllowedValues,
            }),
        }],
    }

    const address = {
        id: 'addressDetails',
        title: 'Address Details',
        className: 'addressDetails',
        'data-testid': 'card-addressDetails',
        fields: [{
            id: 'column2',
            fields:
                getAddressFieldDetails({
                    country,
                    categoriesValue: categories,
                    timeZones,
                    allowedCategories,
                    setAutocomplete,
                    currentPlaceId,
                    value,
                    timeZone,
                    isGoogleMapsServiceLoaded,
                    isAddressType: locationType === LocationType.ADDRESS,
                    getAllowedValues,
                    isProspect,
                    isShippingLocation,
                }),
        }],
    }

    const shipping = {
        id: 'shippingLocation',
        title: 'Shipping Location',
        className: 'shippingLocation',
        'data-testid': 'card-shippingLocation',
        fields: [{
            id: 'column3',
            fields: getShippingFieldDetails(),
        }],
    }

    const service = {
        id: 'serviceCenter',
        title: 'Service Center',
        className: 'serviceCenter',
        'data-testid': 'card-serviceCenter',
        fields: [{
            id: 'column4',
            fields: getServiceFieldDetails({
                getAllowedValues,
            }),
        }],
    }

    const seaport = {
        id: 'seaportDetails',
        title: 'Seaport Details',
        className: 'seaportDetails',
        'data-testid': 'card-seaportDetails',
        fields: [{
            id: 'column2',
            fields: getSeaportFieldDetails({
                country, getAllowedValues,
            }),
        }],

    }

    const railport = {
        id: 'railportDetails',
        title: 'Railport Details',
        className: 'railportDetails',
        'data-testid': 'card-seaportDetails',
        fields: [{
            id: 'column2',
            fields: getSeaportFieldDetails({
                country, getAllowedValues,
            }),
        }],
    }

    const locationTypeFields = {
        [LocationType.ADDRESS]: [
            location,
            address,
        ],
        [LocationType.SEAPORT]: [
            location,
            seaport,
        ],
        [LocationType.RAILPORT]: [
            location,
            railport,
        ],
        [LocationType.AIRPORT]: [
            location,
            airport,
        ],
    }

    return [
        ...(locationTypeFields[locationType] || []),
        isShippingLocation && !isProspect ? shipping : undefined,
        isServiceCenter ? service : undefined,
    ].filter(Boolean)
}

export default getLocationFormFields
